import { useMemo } from "react";

import { useAuth } from "@contexts/auth";
import { useCollection, useDocument } from "@contexts/data";
import useAccessType from "@hooks/use-access-type";
import { usePackages } from "@hooks/use-packages";
import { SchedulerType } from "@lib/data/schemas/scheduler";
import { getSchedulersWithAccounts } from "@lib/schedulers";

import { useGetCalendarOwnerCallback } from "@components/availabilities/AvailabilityOwnerPreview";

type UseSchedulerType = (schedulerId?: string) => {
  data?: SchedulerType;
  loading: boolean;
};

export const useScheduler: UseSchedulerType = (schedulerId) => {
  const { data, loading } = useDocument("schedulers", schedulerId);

  return {
    data,
    loading,
  };
};

type UseSchedulersType = () => {
  data?: (SchedulerType & {
    account?: { id: string };
  })[];
  active?: (SchedulerType & {
    account?: { id: string };
  })[];
  loading: boolean;
};

export const useSchedulers: UseSchedulersType = () => {
  const { aid } = useAuth();
  const { accessType, loading: loadingAccessType } = useAccessType();
  const {
    data: allSchedulers,
    active: allActiveSchedulers,
    loading: loadingSchedulers,
  } = useOrgSchedulers();

  const { data, active } = useMemo(() => {
    if (!allSchedulers || !allActiveSchedulers || !aid || !accessType)
      return {
        data: undefined,
        active: undefined,
      };

    if (accessType === "full") {
      return {
        data: allSchedulers,
        active: allActiveSchedulers,
      };
    }

    const mySchedulers = allSchedulers.filter(
      (scheduler) =>
        scheduler.account?.id === aid ||
        scheduler.roundRobin?.members.some((member) => member.id === aid)
    );

    const myActiveSchedulers = allActiveSchedulers.filter(
      (scheduler) =>
        scheduler.account?.id === aid ||
        scheduler.roundRobin?.members.some((member) => member.id === aid)
    );

    return {
      data: mySchedulers,
      active: myActiveSchedulers,
    };
  }, [allSchedulers, allActiveSchedulers, aid, accessType]);

  return {
    data,
    active,
    loading: loadingSchedulers || loadingAccessType,
  };
};

export const useOrgSchedulers: UseSchedulersType = () => {
  const { oid } = useAuth();
  const { data, loading } = useCollection("schedulers");
  const { data: availabilityBlocks } = useCollection("availabilityBlocks");

  const getCalendarOwner = useGetCalendarOwnerCallback();

  const availabilityBlocksWithMemberId = useMemo(() => {
    return availabilityBlocks?.map((block) => ({
      ...block,
      account: getCalendarOwner(block.writeCalendar?.id, block.accountId),
    }));
  }, [availabilityBlocks, getCalendarOwner]);

  // Add org ID as member ID to zoom calls if it hasn't been set (legacy data)
  const withAccount = useMemo(() => {
    if (!oid) return;

    return getSchedulersWithAccounts(data, availabilityBlocksWithMemberId, oid);
  }, [availabilityBlocksWithMemberId, data, oid]);

  const active = useMemo(() => {
    return withAccount?.filter(({ status }) => status === "active");
  }, [withAccount]);

  return { data: withAccount, active, loading };
};

type UseSchedulersWithPackageCountType = () => {
  data?: (SchedulerType & {
    account?: { id: string };
    packageCount?: number;
  })[];
  loading: boolean;
};

export const useSchedulersWithPackageCount: UseSchedulersWithPackageCountType =
  () => {
    const { data: schedulers, loading: isLoadingSchedulers } = useSchedulers();
    const { packages: allPackages, loading: loadingPackages } = usePackages();

    const useSchedulersWithPackageCount = useMemo(() => {
      if (!schedulers || !allPackages) return;

      return schedulers.map((scheduler) => {
        const packages = allPackages.filter((p) =>
          p.items.some((i) => i.schedulerId === scheduler.id)
        );
        return {
          ...scheduler,
          packageCount: packages.length,
        };
      });
    }, [schedulers, allPackages]);

    return {
      data: useSchedulersWithPackageCount,
      loading: isLoadingSchedulers || loadingPackages,
    };
  };
